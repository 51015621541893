import {
  ClassicEditor as ClassicEditorBase,
  Essentials,
  Autoformat,
  Bold,
  Italic,
  BlockQuote,
  CKBox,
  Heading,
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  PictureEditing,
  Indent,
  Link,
  List,
  Table,
  TableToolbar,
  CloudServices,
  SourceEditing,
  Markdown,
  Clipboard,
  FileRepository,
  Underline,
  Strikethrough,
  Superscript,
  Subscript,
  RemoveFormat,
  ShowBlocks,
  IndentBlock,
  Alignment,
  AutoLink,
  HorizontalLine,
  SpecialCharacters,
  SpecialCharactersEssentials,
  Font,
  CKBoxImageEdit,
  ImageEditing,
  CodeBlock,
  GeneralHtmlSupport,
  ImageResizeEditing,
  ImageResizeHandles,
  Code
} from 'ckeditor5';

import '../css/ckeditor.scss';
import { UploadAdapterPlugin } from './plugins/upload';

export class ClassicEditor extends ClassicEditorBase {

}

export function getPlugin(name) {
  switch (name) {
    case 'clipboard': return Clipboard;
  }
}

const OLD_TO_NEW_ITEM_MAP = {
  Source: 'sourceEditing',
  copyFormat: 'formatPainter', // premium,
  maximize: null, // not exists,
  anchor: null,
  smiley: null,
};

const DEPENDENCIES_MAP = {
  sourceEditing: [SourceEditing],
  undo: [Markdown]
};

const GROUP_MAP = {
  document: ['sourceEditing'],
  mode: [],
  clipboard: [],
  undo: ['undo', 'redo'],
};

export function oldItemToNewItem(oldName) {
  return OLD_TO_NEW_ITEM_MAP[oldName] ?? oldName;
}

export function getItemsFromGroupName(oldGroupName) {
  return GROUP_MAP[oldGroupName] ?? [];
}

export function getPluginDependencies(item) {
  return DEPENDENCIES_MAP[item] ?? [];
}

const IMPORT_MAPS = {
  DiagramsPlugin: () => import('./packages/drawio/diagrams').then((v) => v.DiagramsPlugin),
};

const BUTTON_MAP = {
  Styles: 'style',
  Italic: 'italic',
  Bold: 'bold',
  Underline: 'underline',
  Strike: 'strikethrough',
  Subscript: 'subscript',
  Superscript: 'superscript',
  ShowBlocks: 'showBlocks',
  RemoveFormat: 'removeFormat',
  NumberedList: 'numberedList',
  BulletedList: 'bulletedList',
  Indent: 'indent',
  Outdent: 'outdent',
  Blockquote: 'blockQuote',
  Table: 'insertTable',
  CodeBlock: 'codeBlock',
  InsertDiagram: 'insertDiagram',
  JustifyLeft: 'alignment',
  Link: 'link',
  HorizontalRule: 'horizontalLine',
  SpecialChar: 'specialCharacters',
  TextColor: 'fontColor',
  BGColor: 'fontBackgroundColor',
  Heading: 'heading',
};

const defaultPlugins = [
  Essentials, Heading, CodeBlock,
  ShowBlocks, SourceEditing, Essentials,
  Italic, Bold, Underline, Code,
  Strikethrough, Superscript, Subscript,
  RemoveFormat, List, Indent, IndentBlock,
  BlockQuote, Alignment, Link, AutoLink,
  Table, TableToolbar, HorizontalLine,
  SpecialCharacters, SpecialCharactersEssentials,
  Heading, Autoformat, Font, GeneralHtmlSupport,
  ImageResizeEditing, ImageResizeHandles, Clipboard,
];

const buttonPluginDependencies = {
  italic: [Italic],
  bold: [Bold],
  underline: [Underline],
  strikethrough: [Strikethrough],
  codeBlock: [CodeBlock, Code],
  numberedList: [List],
  bulletedList: [List],
};

/**
 * 
 * @param {object} oldConfig 
 * @param {string} [oldConfig.removeButtons]
 * @param {string} [oldConfig.filebrowserImageUploadUrl]
 * @param {boolean} [oldConfig.markdown=false]
 * @param {string} [oldConfig.language]
 * @returns {object}
 */
export async function convertConfigVer4ToVer5(oldConfig) {
  const newConfig = {
    toolbar: {
      items: [
        'sourceEditing', '|',
        'undo', 'redo', '|',
        'showBlocks', '|',
        'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', '|',
        'removeFormat', '|',
        'numberedList', 'bulletedList', '|',
        'outdent', 'indent', '|',
        'blockQuote', 'codeBlock', '|',
        'alignment', '|',
        'link', '|',
        'insertTable', 'horizontalLine', 'specialCharacters', '|',
        'heading', '|',
        'fontColor', 'fontBackgroundColor', '|',
        'insertDiagram', '|',
      ],
      shouldNotGroupWhenFull: true,
    },
    plugins: [
      await IMPORT_MAPS.DiagramsPlugin(),
      ...defaultPlugins,
    ],
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells'
      ]
    },
    image: {
      toolbar: [
        'imageTextAlternative',
        'editDiagram'
      ]
    },
  };

  const removeButtons = typeof oldConfig.removeButtons === 'string' ? oldConfig.removeButtons.split(',') : [];

  for (const button of removeButtons) {
    const newButton = BUTTON_MAP[button] || button;
    if (newButton) {
      newConfig.toolbar.items = newConfig.toolbar.items.filter((item) => item !== newButton);
    }
  }

  if (removeButtons.includes('Table')) {
    delete newConfig.table;
  }

  if (removeButtons.includes('Image') && !oldConfig.filebrowserImageUploadUrl) {
    delete newConfig.image;
    newConfig.toolbar.items = newConfig.toolbar.items.filter((item) => item !== 'insertDiagram');
    newConfig.plugins = newConfig.plugins.filter((plugin) => {
      return ['DiagramsPlugin', 'Image'].includes(plugin.pluginName) === false;
    });
  }

  if (oldConfig.filebrowserImageUploadUrl) {
    newConfig.filebrowserImageUploadUrl = oldConfig.filebrowserImageUploadUrl;
    newConfig.plugins.push(
      UploadAdapterPlugin, FileRepository, Image,
      PictureEditing, ImageUpload, CloudServices,
      CKBox, CKBoxImageEdit, ImageCaption, ImageEditing, ImageToolbar,
      ImageStyle,
    );
  }

  if (oldConfig.markdown === true) {
    newConfig.plugins.push(Markdown);
  }

  if (typeof oldConfig.language === 'string' && oldConfig.language) {
    newConfig.language = {
      ui: oldConfig.language,
    };

    if (oldConfig.language !== 'en') {
      try {
        const { default: translations } = await import(`../../node_modules/ckeditor5/dist/translations/${oldConfig.language}.js`);
        newConfig.translations = newConfig.translations || [];
        newConfig.translations.push(translations);
      } catch (err) {
        console.log('Cannot load language: %s', oldConfig.language);
      }
    }
  }

  if (newConfig.toolbar.items.includes('heading')) {
    newConfig.heading = {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
        { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
      ]
    };
  }

  for (const button in buttonPluginDependencies) {
    // remove unused plugins
    if (!newConfig.toolbar.items.includes(button)) {
      newConfig.plugins = newConfig.plugins.filter((plugin) => {
        return buttonPluginDependencies[button].includes(plugin) === false;
      });
    }
  }

  for (const button in buttonPluginDependencies) {
    // add missing plugins
    if (newConfig.toolbar.items.includes(button)) {
      for (const plugin of buttonPluginDependencies[button]) {
        if (!newConfig.plugins.includes(plugin)) {
          newConfig.plugins.push(plugin);
        }
      }
    }
  }

  newConfig.diagram = {
    imgFormat: 'png',
    uploadImg: true,
  };

  return newConfig;
}

function triggerEvent(name, editor, data) {
  if (window.CKEDITOR.events) {
    const events = window.CKEDITOR.events[name] || [];
    for (const e of events) {
      e.call(editor, Object.assign({}, data, {
        editor,
      }));
    }
  }
}

window.CKEDITOR = window.CKEDITOR || {};
window.CKEDITOR.events = {};
window.CKEDITOR.instances = {};

window.CKEDITOR.replace = async function (element, config) {
  config = await convertConfigVer4ToVer5(config || {});
  const el = document.getElementById(element);
  const editor = await ClassicEditor.create(el, config);
  window.CKEDITOR.instances[element] = {
    editor,
    updateElement: function () {
      editor.updateSourceElement();
    },
    insertText: function (text) {
      editor.model.change(function (writer) {
        writer.insertText(text, editor.model.document.selection.getFirstPosition());
      });
    }
  };

  triggerEvent('instanceCreated', editor);

  editor.model.document.on('change:data', () => {
    editor.element = Object.assign({ $: el }, editor.element);
    editor.fire('change');
  });

  formsubmithandler();
}

window.CKEDITOR.on = function (event, callback) {
  window.CKEDITOR.events[event] = window.CKEDITOR.events[event] || [];
  window.CKEDITOR.events[event].push(callback);
};

window.ClassicEditor = ClassicEditor;
